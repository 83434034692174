import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/jon/projects/shanti-aid-gatsby/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Chai & Chat`}</h1>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d5dd18d8b53be85d4d65cddc8fe925a/437bf/chai-and-chat-jan23.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGBjZovRFCf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACERID/9oACAEBAAEFAjJ2VlUTksWAfPM//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAACEBMRASsf/aAAgBAQAGPwJ10oQ1MGuP/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFBcf/aAAgBAQABPyFLTBzUIbyQ0ZkiXtIa9P/aAAwDAQACAAMAAAAQuA//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAwEBPxAZAb//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QxK//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRYdH/2gAIAQEAAT8QC8hsGsr5KQoV1qu48dhzvUAi17CsdU83DPuWUCTLsnhP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Chai & Chat",
            "title": "Chai & Chat",
            "src": "/static/6d5dd18d8b53be85d4d65cddc8fe925a/4b190/chai-and-chat-jan23.jpg",
            "srcSet": ["/static/6d5dd18d8b53be85d4d65cddc8fe925a/e07e9/chai-and-chat-jan23.jpg 200w", "/static/6d5dd18d8b53be85d4d65cddc8fe925a/066f9/chai-and-chat-jan23.jpg 400w", "/static/6d5dd18d8b53be85d4d65cddc8fe925a/4b190/chai-and-chat-jan23.jpg 800w", "/static/6d5dd18d8b53be85d4d65cddc8fe925a/e5166/chai-and-chat-jan23.jpg 1200w", "/static/6d5dd18d8b53be85d4d65cddc8fe925a/b17f8/chai-and-chat-jan23.jpg 1600w", "/static/6d5dd18d8b53be85d4d65cddc8fe925a/437bf/chai-and-chat-jan23.jpg 2872w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <hr></hr>
    <p>{`Another successful Chai and Chat in January 2023.`}</p>
    <p>{`Thank you for all that attended.`}</p>
    <p>{`We look forward to seeing you next month.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      